import Chats from 'models/Chats';
import Notification from 'features/Notification';
import Company from 'shared/Icons/Company';
import Help from 'shared/Icons/Help';
import Profile from 'shared/Icons/Profile';
import Projects from 'shared/Icons/Projects';
import Security from 'shared/Icons/Security';
import Tender from 'shared/Icons/Tender';
import { adminRole } from 'shared/helper/adminRole';
import { UserType } from 'types/User';
import { moderatorRole } from 'shared/helper/moderatorRole';
import { Category } from 'services/interfaces/Category';
import { capitalizeFirstLetter } from 'shared/helper';

export const paths = {
  companyTabs: (companyId: string) => [
    {
      title: 'Продукция',
      href: `/company/${companyId}/category/specialMachinery`,
      path: `/company/${companyId}/category`,
    },
    {
      title: 'Каталог',
      href: `/company/${companyId}/catalog`,
      path: `/company/${companyId}/catalog`,
    },
    {
      title: 'Заявки на продукцию',
      href: `/company/${companyId}/leads/incoming`,
      path: `/company/${companyId}/leads`,
    },
    {
      title: 'Тендеры',
      href: `/company/${companyId}/tenders/specialMachinery`,
      path: `/company/${companyId}/tenders`,
    },
    {
      title: 'Заявки на тендеры',
      href: `/company/${companyId}/application/leads/incoming`,
      path: `/company/${companyId}/application/leads`,
    },
    {
      title: 'Структура компании',
      href: `/company/${companyId}/structure`,
      path: `/company/${companyId}/structure`,
    },
    {
      title: 'Сотрудники',
      href: `/company/${companyId}/employees`,
      path: `/company/${companyId}/employees`,
    },
    {
      title: 'Категории',
      href: `/company/${companyId}/notice`,
      path: `/company/${companyId}/notice`,
    },
    {
      title: 'Галерея',
      href: `/company/${companyId}/gallery`,
      path: `/company/${companyId}/gallery`,
    },
    {
      title: 'Партнеры',
      href: `/company/${companyId}/partner`,
      path: `/company/${companyId}/partner`,
    },
    {
      title: 'Проекты',
      href: `/company/${companyId}/projects`,
      path: `/company/${companyId}/projects`,
    },
  ],
  adminTabs: () => [
    {
      title: 'Компании',
      href: `/admin/companies/all`,
      path: `/admin/companies/all`,
    },
    {
      title: 'Пользователи',
      href: `/admin/users`,
      path: `/admin/users`,
    },
    {
      title: 'Анкеты',
      href: `/admin/forms`,
      path: `/admin/forms`,
    },
  ],
  moderatorTabs: () => [
    {
      title: 'Объявления',
      href: `/moderator/catalog/all?category=specialMachinery`,
      path: `/moderator/catalog`,
    },
    {
      title: 'Компании',
      href: `/moderator/companies/all`,
      path: `/moderator/companies`,
    },
    {
      title: 'Пользователи',
      href: `/moderator/users/all`,
      path: `/moderator/users`,
    },
    {
      title: 'Тендеры',
      href: `/moderator/tenders/all?category=specialMachinery`,
      path: `/moderator/tenders`,
    },
    {
      title: 'Каталоги',
      href: `/moderator/categoryCatalogs/all`,
      path: `/moderator/categoryCatalogs`,
    },
  ],
  advertTabs: (companyId: string, id: string) => [
    {
      title: 'Описание',
      href: `/company/${companyId}/category/view/${id}/info`,
      path: `/company/${companyId}/category/view/${id}/info`,
    },
    {
      title: 'Заявки',
      href: `/company/${companyId}/category/view/${id}/leads`,
      path: `/company/${companyId}/category/view/${id}/leads`,
    },
  ],
  tenderTabs: (companyId: string, id: string) => [
    {
      title: 'Описание',
      href: `/company/${companyId}/tenders/view/${id}/info`,
      path: `/company/${companyId}/tenders/view/${id}/info`,
    },
    {
      title: 'Заявки',
      href: `/company/${companyId}/tenders/view/${id}/leads`,
      path: `/company/${companyId}/tenders/view/${id}/leads`,
    },
  ],
  leadsTabs: (companyId: string) => [
    {
      title: 'Продажи',
      href: `/company/${companyId}/leads/incoming`,
      path: `/company/${companyId}/leads/incoming`,
    },
    {
      title: 'Закупки',
      href: `/company/${companyId}/leads/outgoing`,
      path: `/company/${companyId}/leads/outgoing`,
    },
  ],
  advertMarketplaceTabs: (id: string) => [
    {
      title: 'Описание',
      href: `/marketplace/advert/${id}`,
      path: `/marketplace/advert/${id}`,
    },
    {
      title: 'Заявки',
      href: `/marketplace/advert/${id}/leads`,
      path: `/marketplace/advert/${id}/leads`,
    },
    {
      title: 'Водители',
      href: `/marketplace/advert/${id}/drivers`,
      path: `/marketplace/advert/${id}/drivers`,
    },
    {
      title: 'Доступ',
      href: `/marketplace/advert/${id}/access`,
      path: `/marketplace/advert/${id}/`,
    },
  ],
  marketplaceTabs: () => [
    {
      title: 'Недвижимость',
      href: `/marketplace/realty`,
      path: `/marketplace/realty`,
    },
    {
      title: 'Работы и услуги',
      href: `/marketplace/workService`,
      path: `/marketplace/workService`,
    },
    {
      title: 'Спецтехника',
      href: `/marketplace/specialMachinery`,
      path: `/marketplace/specialMachinery`,
    },
    {
      title: 'Cтройматериалы',
      href: `/marketplace/buildingMaterial`,
      path: `/marketplace/buildingMaterial`,
    },
    {
      title: 'Оборудование',
      href: `/marketplace/equipment`,
      path: `/marketplace/equipment`,
    },
    {
      title: 'Изделия',
      href: `/marketplace/product`,
      path: `/marketplace/product`,
    },
  ],
  moderatorCatalogCategoriesTabs: (
    pageName: string,
    categories: Category[]
  ) => {
    return categories.map((category) => ({
      title: `${capitalizeFirstLetter(category.name)} (${
        category.totalAdvert || 0
      })`,
      href: `/moderator/catalog/${pageName}?category=${category.alias}`,
      path: `/moderator/catalog/${pageName}?category=${category.alias}`,
    }));
  },
  moderatorTenderCategoriesTabs: (pageName: string, categories: Category[]) => {
    // TODO нет подсчета на бэке category.totalTender
    return categories.map((category) => ({
      title: `${capitalizeFirstLetter(category.name)} (${0})`,
      href: `/moderator/tenders/${pageName}?category=${category.alias}`,
      path: `/moderator/tenders/${pageName}?category=${category.alias}`,
    }));
  },
  sidebar: () => [
    {
      title: 'Профиль',
      subTitle: 'Профиль',
      href: `/account`,
      path: `/account`,
      icon: <Profile />,
    },
    {
      title: 'Безопасность',
      subTitle: 'Безопасность',
      href: `/secure`,
      path: `/secure`,
      icon: <Security />,
    },
    {
      title: 'Мои компании',
      subTitle: 'Мои компании',
      href: `/company`,
      path: `/company`,
      icon: <Company />,
    },
    {},
    {
      title: 'Проекты',
      subTitle: 'Проекты',
      href: `/projects`,
      path: `/projects`,
      icon: <Projects />,
    },
    {
      title: 'Тендеры',
      subTitle: 'Тендеры',
      href: `/tenders?category=specialMachinery`,
      path: `/tenders?category=specialMachinery`,
      icon: <Tender />,
    },
    {},
    {
      title: 'Помощь',
      subTitle: 'Помощь',
      href: `/doc`,
      path: `/doc`,
      icon: <Help />,
    },
  ],
  appbar: (user: UserType) => [
    {
      title: 'Главная',
      href: `/`,
      path: `/`,
    },
    {
      title: 'Маркетплейс',
      href: `/marketplace`,
      path: `/marketplace`,
    },
    {
      title: 'Тендеры',
      href: `/tenders`,
      path: `/tenders`,
    },
    {
      title: 'Фабрики и заводы',
      href: `/companies`,
      path: `/companies`,
    },
    {
      title: 'Заказы из Китая',
      href: `#`,
      path: `#`,
    },
    adminRole({
      user: user,
      children: {
        title: 'Администрирование',
        href: `/admin/companies/all`,
        path: `/admin/companies/all`,
      },
      notShow: false,
    }),
    moderatorRole({
      user: user,
      children: {
        title: 'Модерирование',
        href: `/moderator`,
        path: `/moderator`,
        // href: `/moderator/catalog/all?category=specialMachinery`,
        // path: `/moderator/catalog/all?category=specialMachinery`,
      },
      notShow: false,
    }),
  ],
};
