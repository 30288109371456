import React, { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { Box, Divider, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import Icons from 'shared/Icons/Notifications';
import useWebSocket from 'react-use-websocket';
import { useSession } from 'next-auth/react';
import theme from 'shared/theme';
import WebApi from 'services/index';
import { DateRange } from 'shared/helper/dateRange';
import { PageContext } from 'shared/lib/toolkit/store/pagecontext';
import Chats from 'models/Chats';
import Types from './Types';

interface Props {
  id?: number;
  type?: string;
  organizationId?: string | string[] | null;
}
interface INotification {
  data: {};
  event: string;
  id: number;
  read: boolean;
  userId: number;
}

export const UI = ({}: Props) => {
  const { data: sessionData } = useSession();
  const [open, setOpen] = useState<boolean>(false);
  const [list, setList] = useState<any>([]);
  const [count, setCount] = useState<number>(0);
  const [listModerator, setListModerator] = useState<any>([]);
  const [countModerator, setCountModerator] = useState<number>(0);
  const [read, setRead] = useState(false);
  const user = sessionData?.user;
  const isModerator = user?.roles.some(
    (role) => role.alias === 'moderator' || role.alias === 'admin'
  );

  const protocols = encodeURIComponent(
    JSON.stringify({ authorization: sessionData?.user?.accessToken })
  );
  const { lastJsonMessage, readyState }: any = useWebSocket(
    `${process.env.NEXT_PUBLIC_NOTIFICATION}`,
    { protocols }
  );

  const { lastJsonMessage: lastJsonMessageModerator }: any = useWebSocket(
    `${process.env.NEXT_PUBLIC_MODERATION}`,
    { protocols }
  );
  const readNotifications = async (item: any) => {
    if (sessionData?.user && !item.read) {
      try {
        await WebApi().Notification.readNotifications({
          id: item.id,
          read: true,
        });
        getReadList();
      } catch (err) {
        console.log(err);
      }
    }
  };
  // const readModerations = async (item: any) => {
  //   if (sessionData?.user && !item.read) {
  //     try {
  //       await WebApi().Moderation.readModerations({
  //         id: item.id,
  //         read: true,
  //       });
  //       getReadListModerator();
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };
  const getRead = async () => {
    try {
      const data = await WebApi().Notification.getList(
        typeof read === 'boolean' ? { read } : {}
      );
      setList(data);
    } catch (err) {
      setList([]);
    }
  };

  const getReadModerator = async () => {
    try {
      const data = await WebApi().Moderation.getList(
        typeof read === 'boolean' ? { read } : {}
      );
      setListModerator(data);
    } catch (err) {
      setListModerator([]);
    }
  };

  const getReadList = async () => {
    try {
      const data = await WebApi().Notification.getList({ read: false });
      const filteredData = data.filter(
        (item: INotification) => item.event !== 'new-employee'
      );
      setCount(filteredData.length);
    } catch (err) {
      setCount(0);
    }
  };
  const getReadListModerator = async () => {
    try {
      const data = await WebApi().Moderation.getList({ read: false });
      setCountModerator(data.length);
    } catch (err) {
      setCountModerator(0);
    }
  };

  useMemo(() => {
    getRead();
  }, [read]);

  useMemo(() => {
    getReadModerator();
  }, [read]);

  useMemo(() => {
    getRead();
  }, [open]);

  useMemo(() => {
    getReadModerator();
  }, [open]);

  useMemo(() => {
    getReadList();
  }, []);

  useMemo(() => {
    getReadListModerator();
  }, []);

  useEffect(() => {
    if (lastJsonMessage?.data) {
      setList([lastJsonMessage, , ...list]);
      setCount(count + 1);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (lastJsonMessageModerator?.data) {
      setListModerator([lastJsonMessageModerator, , ...listModerator]);
      setCountModerator(countModerator + 1);
    }
  }, [lastJsonMessageModerator]);
  interface NotificationCardProps {
    title: string;
    link: string;
    date: string;
    description: string;
    onClick?: () => void;
  }

  interface NotificationItem {
    data?: {
      tenderId?: number;
      tender?: {
        id: number;
        title: string;
      };
      advert?: {
        id: number;
        title: string;
      };
      id: number;
      category?: {
        name: string;
        alias: string;
      };
      organizationId: number;
      organization?: {
        name: string;
      };
    };
  }
  interface NotificationConfig {
    description: (item: NotificationItem) => string;
    link: (item: NotificationItem) => string;
  }

  const NotificationCard: React.FC<NotificationCardProps> = ({
    title,
    link,
    date,
    description,
    onClick,
  }) => {
    return (
      <Box
        onClick={onClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: `1px solid ${theme.palette.grey[400]}`,
          background: theme.palette.grey[100],
          p: 1.5,
          m: 0.5,
          borderRadius: '12px',
          ':hover': {
            backgroundColor: '#006FEE',
            color: '#fff',
            cursor: 'pointer',
          },
        }}
      >
        <Typography variant="body1">
          {description}{' '}
          <Link
            href={link}
            style={{
              cursor: 'pointer',
              opacity: 0.7,
            }}
          >
            {title}
          </Link>
        </Typography>
        <Divider sx={{ height: 50, ml: 1, mr: 1 }} orientation="vertical" />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 150,
            alignItems: 'center',
          }}
        >
          <Typography variant="body1">{date}</Typography>
        </Box>
      </Box>
    );
  };

  // Уведомления для модератора
  const notificationConfig: Record<
    string,
    { description: string; link: string }
  > = {
    'advert-updated': {
      description: 'Было изменено объявление и ожидает модерации:',
      link: '/moderator/catalog/review?category=specialMachinery',
    },
    'advert-created': {
      description: 'Было добавлено новое объявление c названием',
      link: '/moderator/catalog/all?category=specialMachinery',
    },
    'organization-updated': {
      description: 'Была изменена организация и ожидает модерации:',
      link: '/moderator/companies/review',
    },
    'organization-created': {
      description: 'Была добавлена новая организация c названием',
      link: '/moderator/organization/all',
    },
    'tender-updated': {
      description: 'Был изменен тендер и ожидает модерации:',
      link: '/moderator/tenders/review?category=specialMachinery',
    },
    'tender-created': {
      description: 'Был добавлен новый тендер c названием',
      link: '/moderator/tenders/all?category=specialMachinery',
    },
    'catalog-updated': {
      description: 'Был изменен каталог и ожидает модерации:',
      link: '/moderator/categoryCatalogs/review',
    },
    'catalog-created': {
      description: 'Был добавлен новый каталог c названием',
      link: '/moderator/categoryCatalogs/all',
    },
    'user-updated': {
      description: 'Был изменен пользователь и ожидает модерации:',
      link: '/moderator/users/review',
    },
    'user-created': {
      description: 'Был добавлен новый пользователь',
      link: '/moderator/users/all',
    },
  };

  // Уведомления для пользователя
  const notificationConfigUser: Record<string, NotificationConfig> = {
    'lead-created': {
      description: (item) =>
        `Вам пришла заявка №${
          item?.data?.tenderId ? item.data?.tender?.id : item.data?.advert?.id
        } по ${
          item?.data?.tenderId
            ? item.data?.tender?.title
            : item.data?.advert?.title
        }`,
      link: (item) =>
        `/moderator/${item?.data?.tenderId ? 'tenders' : 'adverts'}/all/${item
          ?.data?.id}`,
    },
    'mini-tender-created': {
      description: (item) =>
        `Был объявлен тендер №${item?.data?.id} по категории ${item?.data?.category?.name}`,
      link: (item) =>
        `/tenders/public/${item.data?.category?.alias}/${item.data?.id}`,
    },
    'advert-verified': {
      description: () =>
        'Ваше объявление было проверено и присвоен новый статус "Проверено"',
      link: (item) =>
        `/company/${item.data?.organizationId}/category/specialMachinery?advertStatus=verified`,
    },
    'advert-rejected': {
      description: () =>
        'Ваше объявление было проверено и присвоен новый статус "Отклонено"',
      link: (item) =>
        `/company/${item.data?.organizationId}/category/specialMachinery?advertStatus=rejected`,
    },
    'tender-verified': {
      description: () =>
        'Ваш тендер был проверен и присвоен новый статус "Проверено"',
      link: (item) =>
        `/company/${item.data?.organizationId}/tenders/specialMachinery?tenderStatus=verified`,
    },
    'tender-rejected': {
      description: () =>
        'Ваш тендер был проверен и присвоен новый статус "Отклонено"',
      link: (item) =>
        `/company/${item.data?.organizationId}/tenders/specialMachinery`,
    },
    'catalog-verified': {
      description: () =>
        'Ваш каталог был проверен и присвоен новый статус "Проверено"',
      link: (item) => `/company/${item.data?.organizationId}/catalog`,
    },
    'catalog-rejected': {
      description: () =>
        'Ваш каталог был проверен и присвоен новый статус "Отклонено"',
      link: (item) => `/company/${item.data?.organizationId}/catalog`,
    },
    'organization-verified': {
      description: () =>
        'Ваша организация была проверена и присвоен новый статус "Проверено"',
      link: () => `/company`,
    },
    'organization-rejected': {
      description: () =>
        'Ваша организация была проверена и присвоен новый статус "Отклонено"',
      link: () => `/company`,
    },
    'user-verified': {
      description: () =>
        'Ваши данные пользователя были проверены и присвоен новый  статус "Проверен"',
      link: () => `/account`,
    },
    'user-rejected': {
      description: () =>
        'Ваши данные пользователя были проверены и присвоен новый статус "Отклонен"',
      link: () => `/account`,
    },
    'organization-join': {
      description: (item) =>
        `В компанию ${item.data?.organization?.name} былдобавлен новый сотрудник`,
      link: () => `/account`,
    },
  };

  return (
    <Box>
      <PageContext.Provider value={{}}>
        <Modal onClose={() => setOpen(false)} open={open}>
          <Box
            sx={{
              outline: 'none',
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              position: 'absolute',
              gap: 1,
              width: 500,
              top: 0,
              right: 0,
              background: '#fff',
              height: '100vh',
              '@media (max-width: 638px)': {
                left: 58,
              },
            }}
          >
            <Close
              onClick={() => setOpen(false)}
              sx={{ position: 'absolute', right: 20, cursor: 'pointer' }}
            />
            <Box
              sx={{
                height: '8vh',
              }}
            >
              <Types read={read} setRead={setRead} />
            </Box>
            <Divider variant="middle" sx={{ mb: 2 }} />
            <Box
              sx={{
                height: '92vh',
                overflowY: 'auto',
                zIndex: 999999999,
              }}
            >
              {/* Уведомления Модератора  */}
              {isModerator &&
              Array.isArray(listModerator) &&
              listModerator.length
                ? listModerator.map((item: any) => {
                    const config = notificationConfig[item?.event];
                    if (!config) return null; // Если event не найден, пропускаем

                    const date = DateRange(
                      new Date(item?.ins) as Date
                    ) as string;
                    return (
                      <NotificationCard
                        key={item.id}
                        title={item.data?.name || item.data?.title}
                        link={config.link}
                        date={date}
                        description={config.description}
                      />
                    );
                  })
                : isModerator && (
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant="h5">
                        Новых уведомлений для модерации нет
                      </Typography>
                    </Box>
                  )}
              {Array.isArray(list) && list.length ? (
                list?.map((item: any) => {
                  const config = notificationConfigUser[item?.event];

                  if (config) {
                    return (
                      <Link href={config.link(item)} key={item.id}>
                        <Box
                          onClick={() => readNotifications(item)}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: `1px solid ${theme.palette.grey[400]}`,
                            background: theme.palette.grey[100],
                            p: 1.5,
                            m: 0.5,
                            borderRadius: '12px',
                            ':hover': {
                              backgroundColor: '#006FEE',
                              color: '#fff',
                            },
                          }}
                        >
                          <Typography variant="body1">
                            {config.description(item)}
                          </Typography>
                          <Divider
                            sx={{ height: 50, ml: 1, mr: 1 }}
                            orientation="vertical"
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: 150,
                              alignItems: 'center',
                            }}
                          >
                            <Box sx={{ width: '100%' }}></Box>
                            <Typography variant="body1">
                              {DateRange(new Date(item?.ins) as Date) as string}
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    );
                  }
                  return null;
                })
              ) : (
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h5">Новых уведомлений нет</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Modal>
        <Box
          sx={{
            color: theme.palette.grey[900],
            cursor: 'pointer',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            gap: 1,
            // pt: 0.5,
            pr: 2,
            ':hover': { opacity: 0.7 },
            [theme.breakpoints.down('lg')]: {
              pr: 0,
            },
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          {count ? (
            <Box
              sx={{
                position: 'absolute',
                right: 15,
                borderRadius: '50%',
                p: 1,
                background: 'red',
                width: 3,
                height: 3,
                top: -6,
                color: '#fff',
                fontSize: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {count}
            </Box>
          ) : null}
          {countModerator ? (
            <Box
              sx={{
                position: 'absolute',
                right: 15,
                borderRadius: '50%',
                p: 1,
                background: 'red',
                width: 3,
                height: 3,
                top: -6,
                color: '#fff',
                fontSize: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {countModerator}
            </Box>
          ) : null}
          <Box>
            <Icons />
          </Box>
          <Box
            sx={{
              pr: 3,
              display: 'none',
              [theme.breakpoints.down('lg')]: {
                display: 'flex',
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontFamily: 'Rubik',
                fontWeight: 500,
                color: theme.palette.secondary.light,
              }}
            >
              Уведомления
            </Typography>
          </Box>
        </Box>
      </PageContext.Provider>
    </Box>
  );
};
