import React, { useState } from 'react';
import { Box } from '@mui/material';
import DynamicForm from './DynamicForm';

// Интерфейс для данных вопроса

export const UI: React.FC = () => {
  // Открыть модалку для создания нового вопроса

  return (
    <Box>
      <DynamicForm />
    </Box>
  );
};
