import { Tender as TenderInterface } from '../interfaces/Tender';
import {
  WebApiResponse,
  WebApiMethods,
  WebApiRequest,
} from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';
import WebApi from '..';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Tender extends BaseEntity {
  protected TENDERS: string = 'tenders';

  async getOne(params?: any) {
    const { err, data } = await this.get(this.TENDERS, params);
    if (err) return { err };
    if (data?.category?.id) {
      const { err: _err, data: _data } = await this.get(this.TENDERS, {
        ...params,
        category: data?.category?.alias,
      });
      if (_err) return { err: _err };
      return { data: _data };
    }
  }

  async getList(filter: {}) {
    const { err, data } = await this.get(this.TENDERS, filter);
    return data;
  }

  async getListAllInfoWithDetails(filter: {}) {
    // Получаем список тендеров
    const response = await this.get(this.TENDERS, filter);
    if (!response || response.err || !response.data?.tenders) {
      console.error('Ошибка при получении списка тендеров:', response?.err);
      return { err: response?.err, data: [] };
    }

    const tenders = response.data;
    const newTendersList = [];

    // Обрабатываем тендера по очереди
    for (let tender of tenders.tenders) {
      try {
        // Получаем подробную информацию о текущем тендере
        const tenderResponse = await this.getOne({
          id: tender.id,
          category: tender.category?.alias,
        });

        if (tenderResponse?.err) {
          console.error(
            `Ошибка при получении деталей тендера ${tender.id}:`,
            tenderResponse.err
          );
          // Добавляем тендер как есть, если ошибка при получении деталей
          newTendersList.push(tender);
        } else {
          // Если детали получены, добавляем их к тендеру
          newTendersList.push({
            ...tender,
            ...tenderResponse?.data,
          });
        }
      } catch (error) {
        console.error(`Ошибка при обработке тендера ${tender.id}:`, error);
        // В случае ошибки, добавляем тендер как есть
        newTendersList.push(tender);
      }
    }

    return {
      ...tenders,
      tenders: newTendersList,
    };
  }

  async create(params?: any) {
    params.userId = (await this._getSession())?.user?.id;
    const { err, data } = await this.post(this.TENDERS, params);
    if (err)
      return { err: { message: (err as any).response?.data?.error?.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }

  async change(
    params?: any
  ): Promise<WebApiResponse<{ data: { data: any; error: Error } }>> {
    const { err, data } = await this.put(this.TENDERS, params);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }

  async removeOne(params?: any): Promise<WebApiResponse<any>> {
    const { err, data } = await this.del(this.TENDERS, params);
    if (err) {
      return { err: { message: (err as any).response.data.error.message } };
    }
    return { data };
  }

  async changeStatus({
    tenderId,
    tenderStatus,
  }: {
    tenderId: number;
    tenderStatus: 'review' | 'leadSubmission' | 'draft' | 'withdrawn';
  }): Promise<WebApiResponse<TenderInterface>> {
    const { tenderStatus: tenderStatusList } = await WebApi().Dictionary.getOne(
      { dicts: 'tenderStatus' }
    );
    const { id: statusId } = tenderStatusList.find(
      (item: any) => item.alias === tenderStatus
    );
    const { err, data } = await this.put(this.TENDERS, {
      id: tenderId,
      tenderStatusId: statusId,
    });
    if (err)
      return { err: { message: (err as any).response?.data?.error?.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }
}

export default Tender;
