import { Lead, ApiError } from 'shared/types';
import {
  WebApiResponse,
  WebApiMethods,
  WebApiRequest,
} from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';
import { isAxiosError } from 'axios';

export type LeadResponse<T extends Lead | Lead[]> = {
  data: T;
  error: ApiError;
};

class Moderation extends BaseEntity {
  protected MODERATIONS: string = 'moderations';

  async getOne(guid: string) {
    console.log('get one moderations', guid);
  }

  async getList(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.MODERATIONS, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data.reverse();
  }
  async readModerations(filter: {}): Promise<any> {
    const { err, data } = await this.put(this.MODERATIONS, filter);
    return data;
  }
  async reviewModerations(filter: {}): Promise<any> {
    const { err, data } = await this.put(this.MODERATIONS, filter);
    return data;
  }
}

export default Moderation;
