import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import WebApi from 'services/index';
import { Advert } from 'services/interfaces/Advert';
import { Category } from 'services/interfaces/Category';
import { Company } from 'services/interfaces/Company';
import { Measure } from 'services/interfaces/Measure';
import { PageContext } from 'shared/lib/toolkit/store/pagecontext';

import * as yup from 'yup';

interface FormValues {
  title: string;
  category: string;
  catalogId: string | null;
  brandModel: string;
  characteristic: string;
  price: number | null;
  measureUnitId: string;
  manufactureYear: number;
  organizationId: string;
  address: string;
}

export const useModel = (
  company: Company[],
  category: Category,
  measureUnit: Measure[],
  advert?: Advert
) => {
  const [measure, setMeasure] = useState<
    { key: number; value: number; text: string }[]
  >([]);
  const [companyData, setCompanyData] = useState<
    { key: number; value: number; text: string }[]
  >([]);
  const [selectedCompany, setSelectedCompany] = useState(
    company ? company[0].id : ''
  );
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryName, setSelectedCategoryName] = useState('');
  const router = useRouter();
  const imagesUploadRef = useRef<{ sendImagesHandler: Function }>();
  const {
    companyId,
    myCompanyData,
  }: { companyId: any; myCompanyData: Company } = useContext(PageContext);
  const [categories, setCategories] = useState<
    { key: number; value: string; text: string }[]
  >([]);
  const [selectedCatalogId, setSelectedCatalogId] = useState('');

  useEffect(() => {
    measureUnit &&
      setMeasure(
        measureUnit.map((measure: any) => {
          return {
            key: measure.id,
            value: measure.id,
            text: measure.name,
          };
        })
      );
  }, [measureUnit]);

  useEffect(() => {
    company &&
      setCompanyData(
        company.map((item: any) => ({
          key: item.id,
          value: item.id,
          text: item.name,
        }))
      );
  }, [company]);

  // Поиск названия категории на русском
  const findCategoryName = (
    categories: any[],
    targetAlias: string
  ): string | null => {
    for (const category of categories) {
      if (category.alias === targetAlias) {
        return category.name;
      }

      if (category.categories && category.categories.length > 0) {
        const foundName = findCategoryName(category.categories, targetAlias);
        if (foundName) {
          return foundName;
        }
      }
    }
    return null;
  };

  // Если у нас есть вложенные категории, то будет массив значений, если нет, то одно

  const convertCategory = (category: any): any => {
    return {
      key: category.id,
      value: category.alias,
      text: category.name,
      categories: category.categories
        ? category.categories.map(convertCategory)
        : [],
    };
  };

  useEffect(() => {
    if (category && category.categories && category.categories.length > 0) {
      setCategories(
        category.categories.map((cat: any) => ({
          key: cat.id,
          value: cat.alias,
          text: cat.name,
        }))
      );
      // Возможно нужно будет в будущем, если нужны будут массив из категорий

      // } else if (category && category?.length > 0) {
      //   const convertedCategories = category.map(convertCategory);
      //   setCategories(convertedCategories);
    } else if (category) {
      setCategories([
        {
          key: category.id,
          value: category.alias,
          text: category.name,
        },
      ]);
    }
  }, [category]);

  useEffect(() => {
    if (
      category.categories &&
      category.categories.length > 0 &&
      selectedCategory
    ) {
      const categoryName = findCategoryName(
        category.categories,
        selectedCategory
      );
      if (categoryName) {
        setSelectedCategoryName(categoryName);
      }
    }
  }, [category.categories, selectedCategory]);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: defaultValue(
      advert,
      companyId,
      selectedCatalogId,
      selectedCompany
    ),
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (
    args: any & { category: string },
    advertId: number | undefined
  ) => {
    setLoading(true);
    const { err, data } = advertId
      ? await WebApi().Advert.change({
          ...args,
          id: advertId,
          advertStatus: 'draft',
        })
      : await WebApi().Advert.create(args);
    if (!err) {
      if (imagesUploadRef.current) {
        const advertId = data?.data?.data?.id;
        const { err } = await imagesUploadRef.current.sendImagesHandler({
          advertId,
        });
        if (err) {
          return toast.error(err?.message);
        }
      }
      toast.success(`Спецтехника успешно ${advertId ? 'изменена' : 'создана'}`);

      setTimeout(() => {
        company
          ? router.push(
              `/company/${selectedCompany}/category/${category.alias}?advertStatus=draft`
            )
          : router.reload();
      }, 1300);
    } else {
      setLoading(false);
      toast.error(err?.message);
    }
  };

  return {
    handleSubmit,
    onSubmit,
    category,
    control,
    errors,
    setSelectedCategory,
    selectedCategory,
    selectedCategoryName,
    companyData,
    setSelectedCompany,
    measure,
    imagesUploadRef,
    loading,
    categories,
    setSelectedCatalogId,
    myCompanyData,
    selectedCompany,
  };
};

export const schema = yup.object().shape(
  {
    title: yup
      .string()
      .trim()
      .required('Название продукции обязательно')
      .min(2, 'Название должно содержать минимум 2 символа')
      .max(250, 'Название не должно превышать 250 символов'),

    category: yup.string().required('Выбор категории продукции обязателен'),

    catalogId: yup.string().nullable().notRequired(),

    brandModel: yup
      .string()
      .trim()
      .required('Марка и модель обязательны')
      .min(2, 'Название должно содержать минимум 2 символа')
      .max(250, 'Название не должно превышать 250 символов'),

    characteristic: yup
      .string()
      .trim()
      .nullable()
      .notRequired()
      .transform((_, val) => {
        return val !== '' && val !== null ? val.trim() : null;
      })
      .when('characteristic', {
        is: (value: string) => value?.length,
        then: (rule) =>
          rule
            .min(2, 'Описание должно содержать минимум 2 символа')
            .max(1000, 'Описание не должно превышать 1000 символов'),
      }),

    price: yup
      .number()
      .nullable()
      .typeError('Стоимость должна быть числом')
      .moreThan(0, 'Стоимость должна быть не менее 1')
      .transform((_, val) => (val !== '' && val !== null ? Number(val) : null)),

    measureUnitId: yup.string().required('Выбор единицы измерения обязателен'),

    manufactureYear: yup
      .number()
      .typeError('Дата должна быть числом')
      .min(1900, 'Дата должна быть не менее 1900')
      .max(2030, 'Дата должна быть не более 2030'),

    organizationId: yup.string().required('Выбор организации обязателен'),

    address: yup
      .mixed()
      .required('Адрес обязателен')
      .test('is-address-valid', 'Адрес обязателен', function (value) {
        // Проверяем, является ли значением строка и имеет ли она минимальную длину 2
        if (typeof value === 'string' && value.trim().length >= 2) {
          return true;
        }

        // Проверяем, является ли значением объект и имеет ли он как минимум одно свойство
        if (typeof value === 'object' && Object.keys(value).length > 0) {
          return true;
        }

        // Если не является ни строкой, ни объектом, или не соответствует требованиям, возвращаем false
        return false;
      }),
  },
  [
    ['characteristic', 'characteristic'],
    ['organizationId', 'organizationId'],
  ]
);

export const defaultValue = (
  advert: any,
  companyId: any,
  selectedCatalogId?: any,
  selectedCompany?: any
): FormValues => ({
  title: advert ? `${advert.title}` : '',
  category: advert ? `${advert.category?.alias}` : '',
  catalogId: selectedCatalogId ? null : advert ? `${advert.catalogId}` : null,
  brandModel: advert ? `${advert.brandModel}` : '',
  characteristic: advert ? advert.characteristic : '',
  price: advert ? advert.price : '',
  measureUnitId: advert ? `${advert.measureUnit?.id}` : '',
  manufactureYear: advert ? advert.manufactureYear : '',
  organizationId:
    selectedCompany || (advert ? `${advert.organizationId}` : `${companyId}`),
  address: advert ? `${advert.address}` : '',
});
