import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
  Typography,
  Divider,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { PageContext } from 'store/pagecontext';
import WebApi from 'services/index';

interface FormField {
  id: number;
  name: string;
  formPropType: 'text' | 'int' | 'json' | 'bool';
  options?: { label: string; additionalInfo?: string; showInfo?: boolean }[];
  isOptional?: boolean;
  data?: FormField[];
}

const DynamicForm: React.FC = () => {
  const { form } = useContext(PageContext);
  console.log(form, 'form');
  const [fields, setFields] = useState<any[]>([]);

  const debounceTimers = useRef<{ [key: number]: NodeJS.Timeout | null }>({});
  // Построение дерева из плоского массива
  const buildTree = (flatFields: any[]): FormField[] => {
    const map = new Map<number, FormField>();

    flatFields.forEach((field) => {
      map.set(field.id, {
        id: field.id,
        name: field.name || '',
        formPropType: field.formPropType?.alias || 'text',
        isOptional: field.required,
        options: field.data || [],
        data: [],
      });
    });

    const tree: FormField[] = [];
    flatFields.forEach((field) => {
      const currentNode = map.get(field.id);
      if (!currentNode) return;

      if (field.formTemplatePropId) {
        const parentNode: any = map.get(field.formTemplatePropId);
        if (parentNode) {
          parentNode?.data.push(currentNode);
        }
      } else {
        tree.push(currentNode);
      }
    });

    return tree;
  };

  useEffect(() => {
    if (form?.data?.formTemplateProps) {
      const tree = buildTree(form.data.formTemplateProps);
      setFields(tree);
    }
  }, [form?.data?.formTemplateProps]);
  // Добавление нового поля
  const addField = async (parentId: number | null = null) => {
    try {
      const newFieldRequest = {
        formTemplateId: form?.data.id,
        formTemplatePropId: parentId,
        formPropType: 'text',
        required: false,
        multiple: false,
        formType: 'miniWorksheet',
      };

      const { data } = await WebApi().Form.postFormTemplatesPost(
        newFieldRequest
      );

      const newField = {
        id: data.data.id,
        name: '',
        formPropType: 'text',
        isOptional: false,
        data: [],
      };

      setFields((prevFields) =>
        parentId === null
          ? [...prevFields, newField]
          : prevFields.map((field) =>
              field.id === parentId
                ? {
                    ...field,
                    data: [...field.data, newField],
                  }
                : field
            )
      );
    } catch (error) {
      console.error('Ошибка при добавлении поля:', error);
    }
  };

  // Удаление поля
  const removeField = async (id: number) => {
    try {
      await WebApi().Form.removeFormTemplatesPost({ id });

      const deleteFromTree = (fieldsList: FormField[]): FormField[] =>
        fieldsList
          .filter((field) => field.id !== id)
          .map((field) => ({
            ...field,
            data: deleteFromTree(field.data as any),
          }));

      setFields((prevFields) => deleteFromTree(prevFields));
    } catch (error) {
      console.error('Ошибка при удалении поля:', error);
    }
  };

  // Обновление поля
  const updateField = (id: number, key: keyof FormField, value: any) => {
    // Немедленное обновление локального состояния
    const updateLocalTree = (fieldsList: FormField[]): FormField[] =>
      fieldsList.map((field) =>
        field.id === id
          ? { ...field, [key]: value }
          : { ...field, data: updateLocalTree(field.data || []) }
      );

    setFields((prevFields) => updateLocalTree(prevFields));

    // Дебаунс для запроса к API
    if (debounceTimers.current[id]) {
      clearTimeout(debounceTimers.current[id]!);
    }

    debounceTimers.current[id] = setTimeout(async () => {
      try {
        await WebApi().Form.updateFormTemplatesPost({ id, [key]: value });
      } catch (error) {
        console.error('Ошибка при обновлении поля на сервере:', error);
      }
    }, 1000); // Время дебаунса (500 мс)
  };

  // Обновление вариантов
  const updateOptions = async (
    fieldId: number,
    options: FormField['options']
  ) => {
    try {
      await WebApi().Form.updateFormTemplatesPost({
        id: fieldId,
        data: options,
      });

      const updateTree = (fieldsList: FormField[]): FormField[] =>
        fieldsList.map((field) =>
          field.id === fieldId
            ? {
                ...field,
                options,
              }
            : { ...field, data: updateTree(field.data as any) }
        );

      setFields((prevFields) => updateTree(prevFields));
    } catch (error) {
      console.error('Ошибка при обновлении вариантов:', error);
    }
  };

  // Добавление варианта
  const addOption = (fieldId: number) => {
    const updateTree = (fieldsList: FormField[]): FormField[] =>
      fieldsList.map((field) =>
        field.id === fieldId
          ? {
              ...field,
              options: [
                ...(field.options || []),
                { label: '', additionalInfo: '', showInfo: false },
              ],
            }
          : { ...field, data: updateTree(field.data as any) }
      );

    setFields((prevFields) => updateTree(prevFields));
  };

  // Удаление варианта
  const removeOption = (fieldId: number, optionIndex: number) => {
    const updateTree = (fieldsList: FormField[]): FormField[] =>
      fieldsList.map((field) =>
        field.id === fieldId
          ? {
              ...field,
              options: field.options?.filter((_, idx) => idx !== optionIndex),
            }
          : { ...field, data: updateTree(field.data as any) }
      );

    setFields((prevFields) => updateTree(prevFields));
  };

  // Нумерация в формате 1, 1.1, 1.2
  const generateNumbering = (parentNumber: string, index: number): string => {
    return parentNumber ? `${parentNumber}.${index + 1}` : `${index + 1}`;
  };

  // Отрисовка полей
  const renderField = (
    field: FormField,
    parentNumber: string = '',
    level: number = 1,
    index: number = 0
  ) => {
    const fieldNumber = generateNumbering(parentNumber, index);

    return (
      <Box
        key={field.id}
        sx={{
          marginLeft: `${level * 10}px`,
          marginBottom: '10px',
          marginTop: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Typography>{fieldNumber}</Typography>
          <TextField
            value={field.name}
            onChange={(e) => updateField(field.id, 'name', e.target.value)}
            size="small"
            placeholder="Введите название"
            sx={{ width: '100%' }}
          />
          {level > 1 && (
            <>
              <Select
                value={field.formPropType}
                sx={{ width: '200px' }}
                onChange={(e) =>
                  updateField(field.id, 'formPropType', e.target.value)
                }
                size="small"
              >
                <MenuItem value="text">Текстовое поле</MenuItem>
                <MenuItem value="int">Числовое поле</MenuItem>
                <MenuItem value="json">Выбор одного из вариантов</MenuItem>
                <MenuItem value="bool">Чекбокс</MenuItem>
              </Select>
              <Checkbox
                sx={{ margin: 0, padding: 0 }}
                checked={field.isOptional || false}
                onChange={(e) =>
                  updateField(field.id, 'isOptional', e.target.checked)
                }
              />
            </>
          )}
          {level === 1 && (
            <IconButton
              sx={{ margin: 0, padding: 0 }}
              onClick={() => addField(field.id)}
            >
              <Add />
            </IconButton>
          )}
          <IconButton
            sx={{ margin: 0, padding: 0 }}
            onClick={() => removeField(field.id)}
          >
            <Delete />
          </IconButton>
        </Box>
        {field.formPropType === 'json' && (
          <Box sx={{ marginLeft: '20px', marginTop: '10px' }}>
            <Typography>Варианты:</Typography>
            {field.options?.map((option, idx) => (
              <Box
                key={idx}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <TextField
                  value={option.label}
                  onChange={(e) =>
                    updateOptions(field.id, [
                      ...(field.options || []).map((opt, optIdx) =>
                        optIdx === idx ? { ...opt, label: e.target.value } : opt
                      ),
                    ])
                  }
                  size="small"
                  placeholder={`Вариант ${idx + 1}`}
                />
                <Checkbox
                  checked={option.showInfo || false}
                  onChange={(e) =>
                    updateOptions(field.id, [
                      ...(field.options || []).map((opt, optIdx) =>
                        optIdx === idx
                          ? { ...opt, showInfo: e.target.checked }
                          : opt
                      ),
                    ])
                  }
                />
                {option.showInfo && (
                  <TextField
                    value={option.additionalInfo || ''}
                    onChange={(e) =>
                      updateOptions(field.id, [
                        ...(field.options || []).map((opt, optIdx) =>
                          optIdx === idx
                            ? { ...opt, additionalInfo: e.target.value }
                            : opt
                        ),
                      ])
                    }
                    size="small"
                    placeholder="Доп. информация"
                  />
                )}
                <IconButton
                  onClick={() => removeOption(field.id, idx)}
                  color="error"
                >
                  <Delete />
                </IconButton>
              </Box>
            ))}
            <Button
              onClick={() => addOption(field.id)}
              size="small"
              sx={{ marginTop: '10px' }}
            >
              Добавить вариант
            </Button>
          </Box>
        )}
        {(field.data as any).map((child: any, idx: number) =>
          renderField(child, fieldNumber, level + 1, idx)
        )}
        <Divider sx={{ marginY: 1 }} />
      </Box>
    );
  };
  return (
    <Box sx={{ width: '100%', margin: '0 auto' }}>
      <Typography variant="h5" gutterBottom>
        Динамическая анкета
      </Typography>
      <Button variant="contained" onClick={() => addField(null)}>
        Добавить заголовок
      </Button>
      <Box
        sx={{
          overflow: 'scroll',
          height: '85vh',
          paddingRight: 1,
          marginTop: 1,
        }}
      >
        {fields.map((field, index) => renderField(field, '', 1, index))}
      </Box>
    </Box>
  );
};

export default DynamicForm;
