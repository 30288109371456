import { WebApiResponse } from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';
import WebApi from '..';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Catalog extends BaseEntity {
  protected API_SLUG: string = 'catalogs';
  protected API_STATUSES: string = 'catalog-statuses';
  protected API_ADVERTCATALOG: string = 'advert-catalogs';

  async getList(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.API_SLUG, filter);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    return data;
  }

  async create(filter: {}) {
    const { err, data } = await this.post(this.API_SLUG, filter);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    return data;
  }

  async change(filter: {}): Promise<
    WebApiResponse<{ data: { data: any; error: Error } }>
  > {
    const { err, data } = await this.put(this.API_SLUG, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async postAdvert(filter: {}): Promise<
    WebApiResponse<{ data: { data: any; error: Error } }>
  > {
    const { err, data } = await this.post(this.API_ADVERTCATALOG, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async getAdvertsCatalog(filter: {}): Promise<
    WebApiResponse<{ data: { data: any; error: Error } }>
  > {
    const { err, data } = await this.get(this.API_ADVERTCATALOG, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }
  async delAdvertCatalog(filter: {}): Promise<
    WebApiResponse<{ data: { data: any; error: Error } }>
  > {
    const { err, data } = await this.del(this.API_ADVERTCATALOG, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    return data;
  }

  async removeOne(params?: any): Promise<WebApiResponse<any>> {
    const { err, data } = await this.del(this.API_SLUG, params);
    if (err) {
      return { err: { message: (err as any).response } };
    }
    return data;
  }
  async changeStatus({
    catalogId,
    catalogStatus,
  }: {
    catalogId: number;
    catalogStatus: 'draft' | 'review' | 'verified' | 'rejected';
  }): Promise<WebApiResponse<Catalog>> {
    const { catalogStatus: catalogStatusList } =
      await WebApi().Dictionary.getOne({ dicts: 'catalogStatus' });
    const { id: statusId } = catalogStatusList.find(
      (item: any) => item.alias === catalogStatus
    );
    const { err, data } = await this.put(this.API_SLUG, {
      id: catalogId,
      catalogStatusId: statusId,
    });
    if (err)
      return { err: { message: (err as any).response?.data?.error?.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }
}

export default Catalog;
