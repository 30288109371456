import { UserType } from 'types/User';

type Child = {
  title: string;
  href: string;
  path: string;
};

export const moderatorRole = ({
  user,
  children,
  notShow,
}: {
  user: UserType;
  children: Child;
  notShow: boolean;
}) => {
  const rolesArray = (user && user.roles) || [];
  const isModerator = rolesArray.find((role) => {
    return role.alias === 'moderator';
  });

  return notShow
    ? isModerator
      ? null
      : children
    : isModerator
    ? children
    : null;
};
